import { canUseDOM } from "exenv";
import global from "global";
import React from "react";

// see: app/views/layouts/_current_user_context.json.jb
// ⚡️DOM経由でContextを渡すのでSSR中は参照できない
// ⚡️SSR中はGuest扱いにする

export type CurrentUserContextTypes = {
  readonly guest: boolean;
  readonly defaultLocale: string;
  readonly locale: string;
  readonly userId: string | undefined;
  readonly identifierId: string | undefined;
  readonly fullName: string | undefined;
  readonly avatarUrl: string | undefined;
  readonly email: string | undefined;
  readonly role: string | undefined;
  readonly url: string | undefined;
  readonly hasUnreadBadge: boolean | undefined;
  readonly organizationId: string | undefined;
  readonly organizationSubdomain: string | undefined;
  readonly organizationName: string | undefined;
  readonly organizationInhouse: boolean | undefined;
  readonly organizationFlyerOverviewActive: boolean | undefined;
  readonly canCreateNote: boolean | undefined;
  readonly canIndexDashboard: boolean | undefined;
  readonly canIndexUsers: boolean | undefined;
  readonly canIndexFlyers: boolean | undefined;
  readonly canIndexFlyerShares: boolean | undefined;
  readonly canIndexFlyerApplications: boolean | undefined;
  readonly canIndexFlyerApplicationMails: boolean | undefined;
  readonly canIndexProspects: boolean | undefined;
  readonly canIndexProspectCustomProperties: boolean | undefined;
  readonly canIndexProspectProcessItems: boolean | undefined;
  readonly canShowProspectRegisterForm: boolean | undefined;
  readonly canShowProspectMemo: boolean | undefined;
  readonly canIndexNotes: boolean | undefined;
  readonly canIndexDepartments: boolean | undefined;
  readonly canIndexJobTitles: boolean | undefined;
  readonly canIndexOccupations: boolean | undefined;
  readonly canIndexReadLogs: boolean | undefined;
  readonly canIndexMobilePushMessages: boolean | undefined;
  readonly canIndexMailMessages: boolean | undefined;
  readonly canCreateProspectCustomProperty: boolean | undefined;
  readonly canIndexPageSettings: boolean | undefined;
  readonly canIndexLocations: boolean | undefined;
};

export const initialCurrentUserContextValue: CurrentUserContextTypes = {
  guest: true,
  defaultLocale: "ja",
  locale: "ja",
  userId: undefined,
  identifierId: undefined,
  fullName: undefined,
  avatarUrl: undefined,
  email: undefined,
  role: undefined,
  url: undefined,
  hasUnreadBadge: undefined,
  organizationId: undefined,
  organizationSubdomain: undefined,
  organizationName: undefined,
  organizationInhouse: undefined,
  organizationFlyerOverviewActive: undefined,
  canCreateNote: undefined,
  canIndexDashboard: undefined,
  canIndexUsers: undefined,
  canIndexFlyers: undefined,
  canIndexFlyerShares: undefined,
  canIndexFlyerApplications: undefined,
  canIndexFlyerApplicationMails: undefined,
  canIndexProspects: undefined,
  canIndexProspectCustomProperties: undefined,
  canIndexProspectProcessItems: undefined,
  canShowProspectRegisterForm: undefined,
  canShowProspectMemo: undefined,
  canIndexNotes: undefined,
  canIndexDepartments: undefined,
  canIndexJobTitles: undefined,
  canIndexOccupations: undefined,
  canIndexReadLogs: undefined,
  canIndexMobilePushMessages: undefined,
  canIndexMailMessages: undefined,
  canCreateProspectCustomProperty: undefined,
  canIndexPageSettings: undefined,
  canIndexLocations: undefined,
};

// see: app/views/layouts/_head.html.haml
export const domId = "#currentUserContext";
const dataset = (canUseDOM && global.document.querySelector(domId) && global.document.querySelector(domId).dataset) || {
  guest: true,
  defaultLocale: "ja",
  locale: "ja",
};

export const currentUserData = {
  guest: dataset.guest,
  defaultLocale: dataset.defaultLocal,
  locale: dataset.locale,
  userId: dataset.userId,
  identifierId: dataset.identifierId,
  fullName: dataset.fullName,
  avatarUrl: dataset.avatarUrl,
  email: dataset.email,
  role: dataset.role,
  url: dataset.url,
  hasUnreadBadge: dataset.hasUnreadBadge === "true",
  organizationId: dataset.organizationId,
  organizationSubdomain: dataset.organizationSubdomain,
  organizationName: dataset.organizationName,
  organizationInhouse: dataset.organizationInhouse === "true",
  canCreateNote: dataset.canCreateNote === "true",
  canIndexDashboard: dataset.canIndexDashboard === "true",
  canIndexUsers: dataset.canIndexUsers === "true",
  canIndexFlyers: dataset.canIndexFlyers === "true",
  canIndexFlyerShares: dataset.canIndexFlyerShares === "true",
  canIndexFlyerApplications: dataset.canIndexFlyerApplications === "true",
  canIndexFlyerApplicationMails: dataset.canIndexFlyerApplicationMails === "true",
  canIndexProspects: dataset.canIndexProspects === "true",
  canIndexProspectCustomProperties: dataset.canIndexProspectCustomProperties === "true",
  canIndexProspectProcessItems: dataset.canIndexProspectProcessItems === "true",
  canShowProspectRegisterForm: dataset.canShowProspectRegisterForm === "true",
  canShowProspectMemo: dataset.canShowProspectMemo === "true",
  canIndexNotes: dataset.canIndexNotes === "true",
  canIndexDepartments: dataset.canIndexDepartments === "true",
  canIndexJobTitles: dataset.canIndexJobTitles === "true",
  canIndexOccupations: dataset.canIndexOccupations === "true",
  canIndexReadLogs: dataset.canIndexReadLogs === "true",
  canIndexMobilePushMessages: dataset.canIndexMobilePushMessages === "true",
  canIndexMailMessages: dataset.canIndexMailMessages === "true",
  canCreateProspectCustomProperty: dataset.canCreateProspectCustomProperty === "true",
  canIndexPageSettings: dataset.canIndexPageSettings === "true",
  canIndexLocations: dataset.canIndexLocations === "true",
} as CurrentUserContextTypes;

export const CurrentUserContext = React.createContext(initialCurrentUserContextValue);
